import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Web3Service from "../../services/Web3Service";
import ApiService from "../../services/ApiService";
import axios from "axios";
import FullScreenLoading from "../util/FullScreenLoading";
import {Avatar, Button, Grid, IconButton, Typography} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import badge from "../../images/web3AcademyBadge.png"
import OpenSeaLogo from "../../images/opensea.svg";


const web3Service = Web3Service()
const apiService = ApiService()

const GATEWAY_PREFIX = "https://nftstorage.link/ipfs/"
// const GATEWAY_PREFIX = "https://ipfs.io/ipfs/"

function Viewer() {

    const {tokenId} = useParams()

    const openSeaUrl = process.env.REACT_APP_OPENSEA_BASE_URL + web3Service.CONTRACT_ADDRESS + "/" + tokenId

    const [isLoading, setIsLoading] = useState(true)
    const [metadata, setMetadata] = useState(undefined)
    // const [metadata, setMetadata] = useState({
    //     image: "image",
    //     name: "Rerry's Web3Academy Badge"
    // })

    useEffect( () => {
        setIsLoading(true)

        async function fetchMetadata() {

            let attempts = 0
            let retrievedMetadata = await retrieveMetadata()
            while (retrievedMetadata === null && attempts < 10) {
                retrievedMetadata = await new Promise(resolve => {
                    setTimeout(async () => {
                        attempts++
                        resolve(await retrieveMetadata())
                    }, 4000)
                })
            }
            setIsLoading(false)
            setMetadata(retrievedMetadata.data)
            console.log(retrievedMetadata.data)
        }
        fetchMetadata()
    }, [tokenId])

    if (isLoading || !metadata || !metadata.image) {
        return <FullScreenLoading/>
    }

    async function retrieveMetadata() {
        try {
            const tokenUri = await apiService.fetchTokenUri(tokenId)
            if (tokenUri !== null) {
                const httpUri = tokenUri.replace("ipfs://", GATEWAY_PREFIX)
                return await axios.get(httpUri)
            }
            return null
        } catch (e) {
            console.log(e.message)
            return null
        }
    }

    return (
        <div style={{position: "absolute", left: 0, top: 0, width: "100%", background: "#FFF"}}>
            <div style={{paddingLeft: 32, paddingTop: 16, paddingBottom: 16}}>
                <Typography style={{fontWeight: "heavy", fontSize: "32px"}}>{metadata.name}</Typography>
            </div>
            <div style={{background: "#F0F0F0", textAlign: "center", height: 532}}>
                <Grid container>
                    <Grid item md={6}>
                        <img style={{marginTop: 64, marginBottom: 64}} width={400} src={metadata.image.replace("ipfs://", GATEWAY_PREFIX)} alt={"Web3Academy badge"}/>
                        {/*<img style={{marginTop: 64, marginBottom: 64}} height={500} src={badge} alt={"Web3Academy badge"}/>*/}
                    </Grid>
                    <Grid item md={6}>
                        <div style={{marginTop: 64, paddingLeft: 80, textAlign: "left"}}>
                            <Typography variant={"h4"}>Web3Eduverse</Typography>
                            <Typography>{metadata.description}</Typography>
                            {/*<Typography>This is a description that we can use. It should be long enough to give the illusion of content</Typography>*/}
                            <Typography style={{marginTop: 64}}>To stay up to date with our new modules or get answers from our experts, join our Discord community!</Typography>
                            <Button href={"https://discord.com/invite/HADCCVE4Kc"} style={{marginTop: 16}} color={"primary"} variant={"contained"}>Join our Discord</Button>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <Grid container justifyContent={"center"} alignItems={"center"}>

                <Grid item xs={12}>
                    <div style={{marginTop: 32, paddingBottom: 16, textAlign: "center"}}>
                        <Button color={"info"} style={{textTransform: "none"}} variant={"outlined"} target={"_blank"} href={openSeaUrl} endIcon={<Avatar sx={{ width: 24, height: 24 }} src={OpenSeaLogo} />}>View on OpeaSea</Button>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div style={{marginTop: 8, paddingBottom: 16, textAlign: "center"}}>
                        <IconButton href={"https://facebook.com/sharer/sharer.php?u=" + openSeaUrl} target={"_blank"} style={{color: "#4267B2"}}><FacebookIcon/></IconButton>
                        <IconButton href={"https://twitter.com/intent/tweet?url=" + openSeaUrl} target={"_blank"} style={{color: "#1DA1F2", marginLeft: 8}}><TwitterIcon/></IconButton>
                        <IconButton href={"https://linkedin.com/shareArticle?mini=true&url=" + openSeaUrl} target={"_blank"} style={{color: "#0e76a8", marginLeft: 8}}><LinkedInIcon/></IconButton>
                    </div>
                </Grid>

            </Grid>

            {/*<Grid container alignItems={"center"} justifyContent={"center"} style={{}}>*/}
            {/*    <Grid item xs={6} style={{marginTop: 32}}>*/}
            {/*        <Alert severity="success">*/}
            {/*            <AlertTitle>Congratulations!</AlertTitle>*/}
            {/*            You've successfully minted completion badge as an NFT <strong>check it out below!</strong>*/}
            {/*        </Alert>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={12} style={{textAlign: "center", background: "#f0f0f0"}}>*/}
            {/*        <img style={{marginTop: 64}} width={500} src={metadata.image.replace("ipfs://", GATEWAY_PREFIX)} alt={"Web3Academy badge"}/>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={6} style={{marginTop: 32}}>*/}
            {/*        <Typography style={{marginBottom: 8}}>View it on</Typography>*/}
            {/*        <a target={"_blank"} href={"https://testnets.opensea.io/assets/mumbai/" + web3Service.CONTRACT_ADDRESS + "/" + tokenId}><img alt={"OpenSea logo"} width={150} src={openSeaLogo}/></a>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
        </div>
    );
}

export default Viewer;
